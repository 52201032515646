import axios, { AxiosRequestConfig } from 'axios';
import ENV from './env';
export interface CustomObject {
	[key: string]: string | number | object | boolean | Date;
}
const GenericServices = (
	env: string,
	param: Array<string> | [],
	body?: CustomObject | null,
	msg?: string | null,
	next?: (data: number) => void | null
) => {
	if (!ENV || !ENV[env] || !ENV[env].method) {
		return msg;
	}

	let url = '';
	if (typeof process?.env?.['NEXT_PUBLIC_DOMAIN'] !== 'undefined') {
		url = ` ${ENV[env].endPoint}`;
	} else {
		url = ` ${ENV[env].endPoint}`;
	}
	param.forEach((element) => {
		url = `${url}/${element}`;
	});
	const config: AxiosRequestConfig = {
		url,
		method: ENV[env].method,
		data: body,
		withCredentials: true,
		headers: {
			'Access-Control-Allow-Origin': process.env['NEXT_PUBLIC_DOMAIN'] || '',
		},

		onUploadProgress(progressEvent) {
			if (next) {
				next(
					Number(Number(progressEvent.loaded) / Number(progressEvent.total)) *
						100
				);
			}
		},
	};

	return axios(config).catch((error) => {
		if (error?.response?.status) {
			switch (error.response.status) {
				case 401:
					return {
						status: 401,
						destination: '/forbidden',
					};

				case 400:
					throw error;
				default:
					throw error;
			}
		} else {
			return {
				status: 500,
				destination: '/500',
			};
		}
	});
};
export default GenericServices;
