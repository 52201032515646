'use client';
import React from 'react';
import useSessionClient from './sessionClient';

// React HOC component to check if the user has the required permissions
const PermissionGateway = ({
  requiredPermissions,
  children,
}: {
  requiredPermissions: string[];
  children: React.ReactNode;
}) => {
  const user = useSessionClient();
  const userPermissions = new Set(user?.permissions);
  const hasPermission = requiredPermissions.every((permission) =>
    userPermissions.has(permission)
  );

  return hasPermission ? <>{children}</> : null;
};
export default PermissionGateway;
