'use client'
import { Skeleton, TableCell, TableRow } from '@mui/material';
import React from 'react';
import { v4 } from 'uuid';
function LoadingTable({ rows, columns }: { rows: number; columns: number }) {
 
  return [...Array(rows).fill(0)].map(() => (
    <TableRow key={v4()}>
      {[...Array(columns).fill(0)].map(() => (
        <TableCell key={v4()}>
          <Skeleton
            variant="rectangular"
            width={'100%'}
            height={20}
            animation="wave"
          />
        </TableCell>
      ))}
    </TableRow>
  ));
}

export default LoadingTable;
