'use client';
import { Skeleton } from '@mui/material';
import React from 'react';

type LoadingRectangleProps = {
	width?: number | string;
	height?: number | string;
};

function LoadingRectangle({
	width = 400,
	height = 400,
}: LoadingRectangleProps) {
	return (
		<Skeleton
			variant='rectangular'
			width={width}
			height={height}
			animation='wave'
		/>
	);
}

export default LoadingRectangle;
