'use client';
import { useCookies } from 'next-client-cookies';
import { ISessionUser } from '@negotium/models';
const useSessionClient = (): ISessionUser | null => {
	const cookies = useCookies();
	const token = cookies.get(process.env['NEXT_PUBLIC_AUTH_COOKIE_NAME'] ?? '');
	const sessionCookie = cookies.get(
		process.env['NEXT_PUBLIC_AUTH_COOKIE_PERMISSIONS_NAME'] ?? ''
	);

	if (token) {
		try {
			let dataSession = null;
			if (sessionCookie) {
				dataSession = JSON.parse(sessionCookie);
			}
			if (!dataSession.name || !dataSession.lastName) {
				return null;
			}
			const session: ISessionUser = {
				image: dataSession.image,
				name: dataSession.name,
				lastName: dataSession.lastName,
				permissions: dataSession.permissions,
				token,
			};

			return session;
		} catch (error) {
			return null;
		}
	}
	return null;
};

export default useSessionClient;
