import { type ReactElement, forwardRef } from 'react';

import MuiAlert, { type AlertProps } from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { ENotificationVariant } from '../ErrorContext/ErrorContext';

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
	props,
	ref
) {
	return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const Notification = ({
	notification,
	handleClose,
	message,
}: {
	notification: ENotificationVariant | null;
	handleClose: () => void;
	message: string;
}): ReactElement => {
	return (
		{ notification } && (
			<Snackbar
				autoHideDuration={6000}
				open={notification ? true : false}
				onClose={handleClose}
			>
				<Alert
					severity={notification ?? 'info'}
					sx={{ width: '100%', fontSize: '16px' }}
					onClose={handleClose}
				>
					{message}
				</Alert>
			</Snackbar>
		)
	);
};
export default Notification;
