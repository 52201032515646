import axios, { AxiosRequestConfig } from 'axios';
import ENV from './env';
import { CustomObject } from '@negotium/models';

const MediaService = (
	env: string,
	param: Array<string> | [],
	body: CustomObject | null,
	docs: Array<File> | [],
	next?: (data: number) => void | null
) => {
	const data1 = new FormData();

	if (docs && docs.length > 0) {
		for (let index = 0; index < docs.length; index += 1) {
			const element = docs[index];
			data1.append('file', element);
		}
	}
	if (body && typeof body === 'object') {
		Object.keys(body).forEach((key) => {
			if (Object.hasOwnProperty.call(body, key)) {
				data1.append(key, body[key].toString());
			}
		});
	}

	let url = `${ENV[env].endPoint}`;
	param.forEach((element) => {
		url = `${url}/${element}`;
	});

	const config: AxiosRequestConfig = {
		url,
		method: ENV[env].method,
		data: data1,
		withCredentials: true,
		headers: {
			'Access-Control-Allow-Origin': process.env['DOMAIN'] || '',
		},
		onUploadProgress(progressEvent) {
			if (next) {
				next(
					Number(Number(progressEvent.loaded) / Number(progressEvent.total)) *
						100
				);
			}
		},
	};

	return axios(config);
};

export default MediaService;
