'use client'
import { Skeleton, } from '@mui/material';
import React from 'react';
function LoadingSideBar() {

  return <Skeleton
    variant="rectangular"
    width={240}
    height={"100vh"}
    animation="wave"
  />

}

export default LoadingSideBar;
