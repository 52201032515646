import { KeyPrefix, createInstance } from 'i18next';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next/initReactI18next';
import { getOptions, languages } from './settings';
import { UseTranslationOptions } from 'react-i18next';

const initI18next = async (lng?: string, ns?: string) => {
	const i18nInstance = createInstance();
	await i18nInstance
		.use(initReactI18next)
		.use(resourcesToBackend(() => import(`./locales/${lng}/${ns}.json`)))
		.init({
			...getOptions(),
			lng,
			preload: languages,
		});
	return i18nInstance;
};

export async function UseServerTranslation(
	lng?: 'es' | 'en' | undefined,
	ns?: string,
	options?: UseTranslationOptions<KeyPrefix<string>>
) {
	const i18nextInstance = await initI18next(lng, ns);

	return {
		t: i18nextInstance.getFixedT(
			Array.isArray(ns) ? ns[0] : ns,
			lng,
			options?.keyPrefix
		),
		i18n: i18nextInstance,
	};
}
