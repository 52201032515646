// Express middleware to validate user session
import { type Response, type NextFunction, Request } from 'express';
import dayjs from 'dayjs';

declare module 'express-session' {
	export interface SessionData {
		userId: string;
	}
}

export const auth = async (
	req: Request,
	res: Response,
	next: NextFunction
): Promise<void> => {
	const { db, logger } = req.app.locals;
	const { AUTH_COOKIE_NAME } = process.env;
	if (!AUTH_COOKIE_NAME) {
		throw new Error('Missing TravelPort API credentials');
	}
	if (!AUTH_COOKIE_NAME) {
		logger.error('AUTH_COOKIE_NAME not set');
		res.status(500).send('AUTH_COOKIE_NAME not set');
		return;
	}

	const Token = req.cookies[AUTH_COOKIE_NAME];

	if (Token && Token !== 'undefined') {
		let SessionId = '';
		if (Token && Token.includes(':')) {
			SessionId = Token.split(':')[1].split('.')[0];
		} else {
			SessionId = Token.split('.')[0];
		}
		SessionId = SessionId.replace('s%3A', '');

		const sessionDB = await db.collection('sessions').findOne(
			{
				_id: { $regex: SessionId.toString(), $options: 'i' },
				expires: { $gte: dayjs().toDate() },
			},
			{ sort: { createdAt: -1 } }
		);

		if (sessionDB) {
			if (dayjs().diff(dayjs(sessionDB.expires), 'minutes') > 0) {
				res.status(401).json({ message: 'Unauthorized' });
				return;
			}

			req.session.userId = JSON.parse(sessionDB.session).userId;

			req.session.touch();
			next();
		} else {
			res.status(401).json({ message: 'Unauthorized' });
		}
	} else {
		if (req.headers.authorization === '!!FS*2024!!') {
			next();
		} else {
			res.status(401).json({ message: 'Unauthorized' });
		}
	}
};
