import cryptoJs from 'crypto-js';

export const encryptAES = (text: string, key: string) => {
	const hash = cryptoJs.AES.encrypt(text, key)
		.toString()
		.replace('+', '2pppp2')
		.replace('/', '2ssss2')
		.replace('=', '2eeee2');
	return hash;
};

export const decryptAES = (encryptedBase64: string, key: string) => {
	const hash = encryptedBase64
		.replace('2pppp2', '+')
		.replace('2ssss2', '/')
		.replace('2eeee2', '=');

	const decrypted = cryptoJs.AES.decrypt(hash, key);
	if (decrypted) {
		try {
			const str = decrypted.toString(cryptoJs.enc.Utf8);
			if (str.length > 0) {
				return str;
			}
			return '';
		} catch (e) {
			return e;
		}
	}
	return '';
};
